import React from "react";
import "../styles/pageAbout.css"
const About = () => {
  return (
    <section id="section-about">
      <div>
        <h2>Who iam i ?</h2>
        <p>Hello, I'm Akshai Jayaraj, a dedicated Data Engineer with a strong background in data analysis and management. I currently serve at GramPro Business Services Pvt. Ltd., where I apply my expertise to develop and maintain robust data solutions that drive business success.</p>
        <p>With over a year of experience in the industry, I have honed my skills in data engineering, enabling me to effectively handle complex data challenges. My role involves designing and implementing data pipelines, ensuring data integrity, and optimizing data workflows to support informed decision-making.
        </p>
        <p> I am passionate about leveraging data to uncover insights and contribute to strategic initiatives. My commitment to continuous learning and staying abreast of emerging technologies allows me to adapt to the evolving landscape of data engineering.
        </p>
        <p>Beyond my professional endeavors, I am an avid learner, always seeking opportunities to expand my knowledge and apply innovative solutions to real-world problems.
        </p>
        <p>Feel free to connect with me to discuss data engineering, technology trends, or potential collaborations.
        </p>
      </div>
    </section>
  );
};

export default About;
