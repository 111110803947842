import React from "react";
import AppRoutes from "./routes/appRoutes";
import About from "./pages/pageAbout";
import PortfolioMainHeader from "./components/Header";
import PortfolioMainFooter from "./components/Footer";
const App = () => {
  return (
    <div>
      <PortfolioMainHeader/>
      <AppRoutes />
      <About/>
      <PortfolioMainFooter/>
    </div>
  );
};

export default App;
