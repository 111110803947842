import React from "react";
import "../styles/header.css"

const PortfolioMainHeader = () => {
    return (
        <header>
            <h1>Akshai.</h1>
            <nav>
                <a href="/">Home</a>
                <a href="/about">About</a>
                <a href="/contact">Contact</a>
                <a href="/resume">Resume</a>
            </nav>
        </header>
    )
} 

export default PortfolioMainHeader;