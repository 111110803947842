import React from "react";
import "../styles/pageHome.css"
const Home = () => {
  return (
    <section id="section-home">
      <div id="section-home=contents">
        <h2>Hey i'm</h2>
        <h1>AKSHAI JAYARAJ</h1>
        <p>A software engineer (Not a Mediocre One) 
          who loves to architect solutions.</p>
      </div>
    </section>
  );
};

export default Home;
