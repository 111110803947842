import React from "react";
import "../styles/footer.css"
const PortfolioMainFooter = () =>{
    return (
        <footer>
            <p>&copy; 2024 Portfolio. All Rights Reserved.</p>
            <nav>
                <a href="/git">Git</a>
                <a href="/linkedIn">Linked In</a>
                <a href="/insta">Instagram</a>
            </nav>
        </footer>

    )
}

export default PortfolioMainFooter;